@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@100;200;300;400;600;700;900&family=Montserrat:wght@100;200;300;400;600;700;900&family=Arima+Madurai:wght@100;200;300;400;600;700;900&family=Saira:wght@100;200;300;400;600;700;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.otpcontainer {
	@apply flex justify-between gap-4 mt-8;
}
.otpcontainer input {
	@apply !mr-0 flex-1 outline-none;
}

::selection {
	background-color: #6f0606;
	color: #fffdfd;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.placeholder {
	border-radius: 32px;
	background-image: url("../src/assets/images/placeholder.svg");
	background-repeat: no-repeat;
}

.placeholderSmall {
	border-radius: 32px;
	background-image: url("../src/assets/images/placeholder.svg");
	background-repeat: no-repeat;
}

[data-pc-section="headerrow"] {
	@apply bg-neutral-300;
}

.p-row-odd {
	@apply bg-neutral-300;
}

input[type="file"]::placeholder {
	display: none;
}

@layer utilities {
	/* Hide scrollbar for Chrome, Safari and Opera */
	.no-scrollbar::-webkit-scrollbar {
		display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	.no-scrollbar {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
}
